<template>
    <div class="Home">
        <custList :sysBoxValue="sysBoxValue"></custList>
    </div>
</template>
<script>
import custList from './custList/index.vue'
import titleMixin from '@/mixin/title'
export default {
    name: 'Home',
    mixins: [titleMixin],
    title: function fc() { return this.$t('mx_mobile.Client.1584681452354') }, // 客户
    props: {
        sysBoxValue: {
            type: Array,
            default: function () {
                return []
            }
        }
    },
    data() {
        return {
            msg: 'Home',
            pageUrl: ''
        }
    },
    mounted() {
        this.pageUrl = this.$route.path
    },
    methods: {
    },
    beforeRouteLeave(to, from, next) {
        const flag = this.Global.utils.chosen.close()
        const flag2 = this.Global.utils.actionSheet.close()
        const flag3 = this.Global.utils.prompt.close()
        if (flag || flag2 || flag3) {
            next(false)
        } else {
            next()
        }
    },
    watch: {
        '$route': function (val, old) {
        }
    },
    components: {
        'custList': custList
    }
}
</script>

<style lang="less" scoped>
@import "./zh-cn.less";
@import "./en.less";
</style>
